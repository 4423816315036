window.tinyMceInitFor = function (selector) {
  const darkmode = $('body').hasClass('dark')
  const darkmodeextra = darkmode
    ? { skin: 'oxide-dark', content_css: 'dark' }
    : {}
  const options = {
    selector: selector,
    menubar: false,
    statusbar: false,
    branding: false,
    autoresize: true,
    max_height: 500,
    advlist_bullet_styles: 'default',
    advlist_number_styles: 'default',
    plugins: ['advlist autolink lists link preview anchor autoresize'],
    toolbar:
      'undo redo | ' +
      'bold italic | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent',
  }
  tinymce.init(Object.assign(options, darkmodeextra))
}

$.ajaxSetup({ cache: false })

window.keepalive = function () {
  $.get('/keep-alive')
}
setInterval('window.keepalive()', 1200000)

window.toggleDetailsFor = function (event, id) {
  event.preventDefault()
  $('#message_details_' + id).toggle()
  $('#detail_caret_' + id + ' svg').toggle()
}

$(document).ready(function () {
  $('.popconfirm').popConfirm()
})

$(document).ready(function () {
  $('#otpform').submit(function (event) {
    event.preventDefault()
    $.post(
      '/verifyotp',
      {
        otpcode: $('#otpcode').val(),
        _token: document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content'),
      },
      function (result) {
        if (result.status === 'OK') {
          window.location.href = result.redirect
        } else {
          $('#otpform').find('small').html(result.reason).css('opacity', '1')
          $('#otpcode').css('border-color', '#f55447')
        }
      },
      'json'
    )
  })
})

$(document).ready(function () {
  tinyMceInitFor('.tinyeditor')
})
