window.toggled = function (id) {
  const div = document.getElementById('fold' + id)
  if (div.style.display === 'none') {
    div.style.display = 'block'
    document.getElementById('foldbtn' + id).innerHTML = document.getElementById(
      'foldbtn' + id
    ).dataset.hidetext
  } else {
    div.style.display = 'none'
    document.getElementById('foldbtn' + id).innerHTML = document.getElementById(
      'foldbtn' + id
    ).dataset.showtext
  }
}
